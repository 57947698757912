import storicoCollaboratoriViews from '@paghe/views/anagrafiche/storico/collaboratori'

const tabPropsDefault: TabProps = {
    TITLE: 'Storico validità collaboratori',
    ICON: 'fa-regular fa-users-between-lines',
    GROUP: 'storicoValColl',
    CODPERMESSO: 'ANAG_COLL',
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        id: Number(route.params?.id),
        year: Number(route.params.year),
        month: Number(route.params.month),
        //day: Number(route.params?.day ?? 1),
        dittaId: Number(route.params.dittaId),
        dittaPadreId: Number(route.params.dittaPadreId),
    }
}

export const SETTINGS = Object.freeze({
    ELENCO: {
        TITLE: 'Elenco collaboratori - Storico validità - Anagrafica - Corrente - Gestione - Paghe',
        LABEL: 'Storico collaboratori',
        PATH: 'storicoCollaboratore/:year/:month/:dittaId/:dittaPadreId',
        NAME: 'StoricoElencoCollaboratore',
        TAB: { ...tabPropsDefault },
    },
    DETTAGLIO: {
        TITLE: 'Dettaglio collaboratore - Collaboratori - Storico validità - Anagrafici - Gestione - Paghe',
        LABEL: 'Dettaglio storico collaboratore',
        PATH: 'storicoCollaboratore/:year/:month/:dittaId/:dittaPadreId/:id',
        NAME: 'StoricoCollaboratoreDettaglio',
        TAB: { ...tabPropsDefault, TITLE: 'Storico validità collaboratore' },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.ELENCO.PATH,
            name: SETTINGS.ELENCO.NAME,
            component: storicoCollaboratoriViews.Elenco,
            meta: {
                title: SETTINGS.ELENCO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: propsFn,
        },
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            props: propsFn,
            component: storicoCollaboratoriViews.dettaglio.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
        },
    ],
}
