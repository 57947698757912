import { defineStore } from 'pinia'
import { ref, reactive, Ref, computed, shallowReactive } from 'vue'
import { pagheAPI } from '@/api'
import { STORES_NAMES } from '@/constants'
import { useDynamicStore } from '@/composables'
import { ROUTES_COLLABORATORE } from '../routes/route-paghe-collaboratore'
import { useGlobalStore } from '@/stores'

import { checkDoppioCedolino } from '@/scripts/utils/dipendenteUtils'
import utyDate from '@/scripts/services/date'
import { isTableUPActive } from '@paghe/views/anagrafiche/ditte/scripts'

const useCollaboratoreStore = defineStore(
    STORES_NAMES.COLLABORATORE,
    () => {
        const _isNote = ref(false)
        const ditta = ref(null)
        const globalStore = useGlobalStore()

        const dataLookup = shallowReactive(new Map())
        const toSave = ref(false)
        const focusElementUID = ref('')
        const id = ref(-1) //id entita visualizzate in pagina (es id dipteProdutt)
        const idDipte = ref(-1)
        const state: CollaboratoreStoreState = reactive({
            data: {
                codice: '',
                nome: '',
                cognome: '',
                matricola: '',
            },
            idCollaboratoreInsert: 0,
            isCollaboratoreElaborato: false,
            listArchivi: [],
            listTabelle: [],
            routeList: String,
            props: null,
            dataChild: {
                childName: null,
                origin: null,
                originPadre: null,
                form: null,
                isPadre: true,
                gestUPFigliaInit: false,
                gestUPFiglia: false,
                //focussedItem: '',
            },
            escludiCessati: 2,
        })

        const getIdDittaPadre = computed(() => {
            let id =
                state.props?.dittaPadreId == 0 ? state.props.dittaId : state.props?.dittaPadreId

            return id ? id : -1
        })

        const isElaborato = computed(() => {
            return state.data?.isElaboratoNelMese
        })

        //header pagine di dettaglio
        async function getData(props: CollaboratoreProps) {
            const params: DiptePayload = {
                requireHasFutureValidity: false,
                periodoDiRiferimento: {
                    year: Number(props.year),
                    month: Number(props.month),
                    day: Number(props.day),
                },
                include: "['ditta']",
                filter: `['id', '=', '${props.id}']`,
                select: JSON.stringify([
                    'codice',
                    'codiceDitta',
                    'cognome',
                    'containsDipteNoteCalc',
                    'dipteAlteraDitta.codAliquota',
                    'dipteDoppioCedolino.dataInizio',
                    'dipteRapporto.codPosizioneCalc',
                    'dipteRapporto.codQualifica',
                    'dipteRapporto.codRichiamato',
                    'dipteRapporto.dataAssunzione',
                    'dipteRapporto.dataCessazione',
                    'dipteRapporto.isDoppioCedolinoNelMese',
                    'dipteUnitaProduttiva.dittaUP.dittaUPOpzione.isDiario', //puo essere del padre
                    'dipteUnitaProduttiva.dittaUP.dittaUPRagioneSociale.denominazioneAggiuntiva',
                    'dipteUnitaProduttiva.dittaUP.dittaUPRagioneSociale.ragioneSociale',
                    'dipteUnitaProduttiva.dittaUP.dittaUPTabellaAttivaList.nomeTabella',
                    'dipteUnitaProduttiva.dittaUP.unitaProduttiva',
                    'ditta.codiceDitta',
                    'ditta.dittaUPOpzione.isDiario',
                    'id',
                    'isElaboratoNelMese',
                    'matricola',
                    'nome',
                    'ViewDiptePresenzaAlterazioniList.alterazione',
                    'ViewDiptePresenzaAlterazioniList.gruppo',
                ]),
            }
            //state.data = null //TODO VALUTARE DI REINIZIALIZZARE L"OBJECT POICHE POTREBBE PORTARE PROBLEMI NELLA NAVIGAZIONE
            try {
                const response = await pagheAPI.anagrafiche.dipendenti.dipte.get(params)
                const { responseStatus, data } = response.data
                if (responseStatus.isSuccessful) {
                    state.data = data?.[0]

                    const up = data?.[0]?.dipteUnitaProduttiva?.dittaUP

                    let ragSociale =
                        `${data?.[0]?.ditta?.codiceDitta} - ${up?.unitaProduttiva} - ` +
                        up?.dittaUPRagioneSociale?.ragioneSociale
                    if (up?.dittaUPRagioneSociale?.denominazioneAggiuntiva) {
                        ragSociale += ` - ${up.dittaUPRagioneSociale.denominazioneAggiuntiva}`
                    }

                    ditta.value = {
                        codice: data?.[0]?.ditta?.codiceDitta,
                        ragSociale: ragSociale,
                    }
                    isNote.value = !!state.data?.containsDipteNoteCalc
                }
            } catch (err) {
                console.error('Errore durante la chiamata: ', err)
            }
        }
        const isDiario = computed(() => {
            const isFiliale = !!state.data?.dipteUnitaProduttiva?.dittaUP.idPadre
            if (isFiliale) {
                const isDiario = isTableUPActive(
                    state.data?.dipteUnitaProduttiva?.dittaUP?.dittaUPTabellaAttivaList
                        ?.nomeTabella,
                    'Ditta_UPOpzione',
                )
                return isDiario
                    ? state.data?.ditta?.dittaUPOpzione?.isDiario
                    : get(state.data, 'dipteUnitaProduttiva.dittaUP.dittaUPOpzione.isDiario', false)
            }
            return state.data?.ditta?.dittaUPOpzione?.isDiario ?? false
        })
        // nome child tab attiva
        const activeChildName: Ref<string | null> = ref(null)

        const headerDate = ref({
            inizio: null,
            fine: null,
            validitaFutura: false,
        })

        function setProps(props: any) {
            state.props = { ...props }
        }

        function setData(newData: any) {
            if (newData) {
                //setto i dati nel dettaglio della ditta
                state.data = { ...newData }
            } else console.log('Elemento non trovato')
        }

        const isDoppioCedolinoNelMese = computed(() => {
            let dipendente = state.data
            let periodoElabStr = `${state.props?.year}-${state.props?.month}` //solo year e month, il day non e' riferito alla ditta
            return checkDoppioCedolino(dipendente, periodoElabStr)
        })

        const getPeriodo = computed(() => {
            return {
                periodoDiRiferimento: {
                    year: state.props?.year,
                    month: state.props?.month,
                    day: state.props?.day,
                },
            }
        })

        const getCodQualifica = computed(() => {
            return state.data?.dipteRapporto?.codQualifica
                ? state.data?.dipteRapporto?.codQualifica
                : null
        })

        const getCodPosizione = computed(() => {
            return state.data?.dipteRapporto?.codPosizioneCalc
                ? decodeCodPosizione(state.data?.dipteRapporto?.codPosizioneCalc)
                : null
        })

        const getTitle = computed(() => {
            const codice = state.data?.codice ? state.data?.codice : ''
            const cognome = state.data?.cognome ? state.data?.cognome : ''
            const nome = state.data?.nome ? state.data?.nome : ''
            return [codice, `${cognome} ${nome}`]
        })

        const getDoppioCedolinoText = computed(() => {
            return isDoppioCedolinoNelMese.value
                ? `Doppio Cedolino nel mese ${utyDate.format(
                      getPeriodo.value.periodoDiRiferimento,
                  )}`
                : ''
        })

        const localTab = ref(0)

        const dataChildFilled = computed(() => {
            return (
                state.dataChild &&
                (state.dataChild.origin || state.dataChild._grid) &&
                state.dataChild.childName === activeChildName.value
            )
        })

        const getDatiDitta = computed(() => {
            return ditta
        })

        function resetPageData(resetLookup: boolean = true, navigation: boolean = false) {
            resetBaseData(resetLookup, navigation)
            //reset dati aggiuntivi
            state.dataChild.origin = null
            state.dataChild.originPadre = null
            state.dataChild.form = null
            if (state.dataChild.changeMap) state?.dataChild?.changeMap?.clear() //vedere se metterlo qui o in resetBaseData
            state.dataChild._grid = null
        }

        function resetBaseData(resetLookup: boolean = true, navigation: boolean = false) {
            state.dataChild.childName = null
            state.dataChild.origin = null
            if (resetLookup) {
                dataLookup.clear() //PER NUOVA GESTIONE LOOKUP
            }
            state.dataChild.isPadre = true
            if (state.dataChild.changeMap) state.dataChild.changeMap.clear() //chiedere a Giacomo
            resetDataHeader()
            focusElementUID.value = ''
            if (!navigation) {
                //operazioni da fare solo se non si sta navigando tra le ditte
                localTab.value = 0
            }
            toSave.value = false
        }

        function resetDataHeader() {
            headerDate.value = { inizio: null, fine: null, validitaFutura: false }
        }

        function initStateData() {
            state.data = { nome: null, cognome: null, codice: null, id: null }
        }

        function resetActiveChildName() {
            activeChildName.value = ROUTES_COLLABORATORE.COLLABORATORE_ANAGRAFICA.NAME
        }

        async function deleteInsertEntity(): Promise<any> {
            if (state.data == null) return false

            if (state?.data?.id && state.data.id > 0) {
                globalStore.loadingSet()
                //caso in cui la ditta non e' stata creata quindi sono nella prima pagina del wizard
                let payload = {
                    ...getPeriodo.value,
                    id: state.data.id,
                    isNuovaValidita: false,
                }

                let result = await pagheAPI.anagrafiche.dipendenti.dipte.delete(payload)
                globalStore.loadingUnset()
                //todo ricontrollare
                return result != null
            }

            return true
        }

        function decodeCodPosizione(code: string): string {
            let strPosCollaboratore = 'n.a.'

            if (code != null) {
                switch (code.toUpperCase()) {
                    case 'N':
                        strPosCollaboratore = 'Nuovo Assunto'
                        break
                    case 'F':
                        strPosCollaboratore = 'In Forza'
                        break
                    case 'K':
                        strPosCollaboratore = 'Nuovo assunto in parcheggio'
                        break
                    case 'H':
                        strPosCollaboratore = 'Assunto e cessato nel mese'
                        break
                    case 'L':
                        strPosCollaboratore = 'Cessato nel mese'
                        break
                    case 'C':
                        strPosCollaboratore = 'Cessato nei mesi precedenti'
                        break
                    case 'R':
                        strPosCollaboratore = 'Richiamato'
                        break
                    default:
                        break
                }
            }
            return strPosCollaboratore
        }

        function setCodQualifica(codQualifica: string) {
            if (!state.data?.dipteRapporto) return
            state.data.dipteRapporto.codQualifica = codQualifica
        }

        function setDatiDitta(ditta: any = null) {
            ditta.value = ditta
        }

        function setCodPosizione(codPosizioneCalc: string = '') {
            if (!state.data?.dipteRapporto) {
                state.data.dipteRapporto = { codPosizioneCalc: codPosizioneCalc }
                return
            }

            state.data.dipteRapporto.codPosizioneCalc = codPosizioneCalc
        }

        // const isDiario = computed(() => {
        //     return state.data?.dittaUPOpzione?.isDiario ? state.data.dittaUPOpzione.isDiario : false
        // })

        const isNote = computed({
            get: () => _isNote.value,
            set: (val: boolean) => (_isNote.value = val),
        })

        const noteContext = computed(() => ({
            entityId: state.data!.id,
            entityType: 'DIPTE',
            isNote,
            title: `collaboratore: ${state.data!.codice} - ${state.data!.cognome} ${
                state.data!.nome
            }`,
            vCodDes: 'TAG-ALERT-COLL',
        }))

        return {
            ...useDynamicStore(),
            activeChildName,
            ditta,
            dataChildFilled,
            dataLookup,
            deleteInsertEntity,
            focusElementUID,
            getData,
            getDatiDitta,
            getDoppioCedolinoText,
            getTitle,
            getPeriodo,
            getIdDittaPadre,
            getCodQualifica,
            getCodPosizione,
            headerDate,
            isElaborato,
            id,
            idDipte,
            isNote,
            isDiario,
            isDoppioCedolinoNelMese,
            initStateData,
            localTab,
            noteContext,
            resetPageData,
            resetBaseData,
            resetActiveChildName,
            state,
            setProps,
            setData,
            setCodQualifica,
            setDatiDitta,
            setCodPosizione,
            toSave,
        }
    },
    {
        persist: false,
    },
)

export default useCollaboratoreStore
