import { call } from '@/scripts/lib/http'
import type { AxiosResponse } from 'axios'

const BASE_URL = '/Elaborazione'

class API {
    @call({ url: `${BASE_URL}/GetContatoriElaborazione` })
    static async getContatoriElaborazione(payload: GetContatoriElaborazione): Promise<AxiosResponse<
        BasicResponsePayload<{
            contatoriList: ContatoriElaborazione
            infoContatoriElaborazioneList: null
        }>
    > | null> {
        return null
    }
}

export default {
    getContatoriElaborazione: API.getContatoriElaborazione,
}
