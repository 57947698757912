//US-12380 lista pagine collaboratore
import { CollaboratoreLista } from '@/views/Paghe/Anagrafiche/Collaboratore/Lista'
import { CollaboratoreDettaglio, Childs } from '@/views/Paghe/Anagrafiche/Collaboratore/Dettaglio'
import entities from '@paghe/entities'

const tabPropsDefault: TabProps = {
    TITLE: 'Anagrafica collaboratore',
    ICON: 'fa-regular fa-users-between-lines',
    GROUP: 'anagraficaCollaboratore',
}

export const ROUTES_COLLABORATORE = Object.freeze({
    COLLABORATORE_LISTA: {
        TITLE: 'Elenco collaboratori - Collaboratore - Anagrafici - Gestione - Paghe',
        LABEL: 'Elenco collaboratori',
        PATH: 'collaboratore/:gestione/:year/:month/:dittaId/:dittaPadreId',
        NAME: 'CollaboratoreLista',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_DETTAGLIO: {
        TITLE: 'Dettaglio collaboratore - Collaboratore - Anagrafici - Gestione - Paghe',
        LABEL: 'Dettaglio collaboratore',
        PATH: 'collaboratore/:gestione/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod/:onlyEntity?',
        NAME: 'collaboratoreDettaglio',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_ANAGRAFICA: {
        TITLE: null,
        LABEL: 'Dati anagrafici',
        PATH: 'anagrafica',
        NAME: 'collaboratoreAnagrafica',
        TAB: { ...tabPropsDefault },
    },

    COLLABORATORE_AREA_AZIENDALE: {
        TITLE: null,
        LABEL: 'Area aziendale',
        PATH: 'areaproduttiva',
        NAME: 'CollaboratoreAreaAziendale',
        TAB: { ...tabPropsDefault },
    },

    COLLABORATORE_RAPPORTO: {
        TITLE: null,
        LABEL: 'Gestione collaborazione',
        PATH: 'rapporto',
        NAME: 'CollaboratoreRapporto',
        TAB: { ...tabPropsDefault },
    },

    COLLABORATORE_DETRAZIONI: {
        TITLE: null,
        LABEL: 'Detrazioni Fiscali',
        PATH: 'detrazioni',
        NAME: 'CollaboratoreDetrazioni',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_OPZIONI: {
        TITLE: null,
        LABEL: 'Opzioni',
        PATH: 'opzioni',
        NAME: 'CollaboratoreOpzioni',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_IMPOSTAZIONI_CEDOLINO: {
        TITLE: null,
        LABEL: 'Impostazioni cedolino 1',
        PATH: 'impostazionicedolino',
        NAME: 'CollaboratoreImpostazioniCedolino',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_IMPOSTAZIONI_CEDOLINO2: {
        TITLE: null,
        LABEL: 'Impostazioni cedolino 2',
        PATH: 'impostazioniCedolino2',
        NAME: 'CollaboratoreImpostazioniCedolino2',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_730: {
        TITLE: null,
        LABEL: 'Mod.730',
        PATH: 'mod730',
        NAME: 'Collaboratore730',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_REDDITI_EROGATI: {
        TITLE: null,
        LABEL: 'Redditi altri sogg.',
        PATH: 'redditierogati',
        NAME: 'CollaboratoreRedditiErogati',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_STORICO: {
        TITLE: null,
        LABEL: 'Storico',
        PATH: 'storico',
        NAME: 'CollaboratoreStorico',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_SCADENZIARIO: {
        TITLE: null,
        LABEL: 'Scadenziario',
        PATH: 'scadenziario',
        NAME: 'CollaboratoreScadenziario',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_DIARIO: {
        TITLE: null,
        LABEL: 'Diario',
        PATH: 'diario',
        NAME: 'CollaboratoreDiario',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_EREDI: {
        TITLE: null,
        LABEL: 'Eredi',
        PATH: 'eredi',
        NAME: 'CollaboratoreEredi',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_PRECEDENTI_RAPPORTI: {
        TITLE: null,
        LABEL: 'Prec.rapporti di lavoro',
        PATH: 'CollaboratorePrecRapporto',
        NAME: 'CollaboratorePrecRapporto',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_CAMPIPERSONALIZZATI: {
        TITLE: null,
        LABEL: 'Campi personalizzati',
        PATH: 'CollaboratoreCampiPersonalizzati',
        NAME: 'CollaboratoreCampiPersonalizzati',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_ALTERAZIONI_CONTRIBUTIVE: {
        TITLE: null,
        LABEL: 'Contributive',
        PATH: 'collaboratoreAlterazioniContributive',
        NAME: 'collaboratoreAlterazioniContributive',
        TAB: { ...tabPropsDefault },
    },
})

export default [
    {
        path: ROUTES_COLLABORATORE.COLLABORATORE_LISTA.PATH,
        name: ROUTES_COLLABORATORE.COLLABORATORE_LISTA.NAME,
        component: CollaboratoreLista,
        meta: {
            title: ROUTES_COLLABORATORE.COLLABORATORE_LISTA.TITLE,
            permissions: {
                codPermesso: 'ANAG_COLL',
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        props: true,
    },
    {
        path: ROUTES_COLLABORATORE.COLLABORATORE_DETTAGLIO.PATH,
        name: ROUTES_COLLABORATORE.COLLABORATORE_DETTAGLIO.NAME,
        props: true,
        component: CollaboratoreDettaglio,
        meta: {
            title: ROUTES_COLLABORATORE.COLLABORATORE_DETTAGLIO.TITLE,
            permissions: {
                codPermesso: 'ANAG_COLL',
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        children: [
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_ANAGRAFICA.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_ANAGRAFICA.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_ANAGRAFICA.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_ANAGRAFICA.TAB,
                orderWizard: 0,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.Anagrafica],
                component: Childs.CollaboratoreAnagrafica,
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO01',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_AREA_AZIENDALE.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_AREA_AZIENDALE.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_AREA_AZIENDALE.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_AREA_AZIENDALE.TAB,
                orderWizard: 1,
                props: true,
                entity: [
                    entities.dipendente.DipendenteEntity.CentroCosto,
                    entities.dipendente.DipendenteEntity.UnitaProduttiva,
                ],
                component: Childs.CollaboratoreAreaProduttiva,
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO03',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_RAPPORTO.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_RAPPORTO.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_RAPPORTO.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_RAPPORTO.TAB,
                orderWizard: 2,
                props: true,
                component: Childs.CollaboratoreRapporto,
                entity: [
                    entities.dipendente.DipendenteEntity.AlteraDitta,
                    entities.dipendente.DipendenteEntity.Collaboratore,
                    entities.dipendente.DipendenteEntity.Ente,
                    entities.dipendente.DipendenteEntity.PosizioneInail,
                    entities.dipendente.DipendenteEntity.Prestito,
                    entities.dipendente.DipendenteEntity.Rapporto,
                ],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO06',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_DETRAZIONI.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_DETRAZIONI.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_DETRAZIONI.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_DETRAZIONI.TAB,
                orderWizard: 3,
                props: true,
                component: Childs.CollaboratoreDetrazioni,
                entity: [
                    entities.dipendente.DipendenteEntity.Detrazione,
                    entities.dipendente.DipendenteEntity.CaricoFamiglia,
                ],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO07',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_OPZIONI.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_OPZIONI.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_OPZIONI.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_OPZIONI.TAB,
                orderWizard: 4,
                props: true,
                component: Childs.CollaboratoreOpzioni,
                entity: [entities.dipendente.DipendenteEntity.Opzione],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO11',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_STORICO.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_STORICO.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_STORICO.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_STORICO.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratoreStorico,
                entity: [entities.dipendente.DipendenteEntity.Storico],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO15',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_IMPOSTAZIONI_CEDOLINO.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_IMPOSTAZIONI_CEDOLINO.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_IMPOSTAZIONI_CEDOLINO.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_IMPOSTAZIONI_CEDOLINO.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratoreImpostazioniCedolino1,
                entity: [entities.dipendente.DipendenteEntity.SindENettoC],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO12',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_IMPOSTAZIONI_CEDOLINO2.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_IMPOSTAZIONI_CEDOLINO2.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_IMPOSTAZIONI_CEDOLINO2.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_IMPOSTAZIONI_CEDOLINO2.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratoreImpostazioniCedolino2,
                entity: [
                    entities.dipendente.DipendenteEntity.VoceFissa,
                    entities.dipendente.DipendenteEntity.Prestito,
                    entities.dipendente.DipendenteEntity.EnteFisso,
                    entities.dipendente.DipendenteEntity.NotaCedoFissa,
                ],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO12',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_730.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_730.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_730.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_730.TAB,
                orderWizard: null,
                props: true,
                component: Childs.Collaboratore730,
                entity: [entities.dipendente.DipendenteEntity[730]],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO13',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_REDDITI_EROGATI.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_REDDITI_EROGATI.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_REDDITI_EROGATI.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_REDDITI_EROGATI.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratoreRedditiErogati,
                entity: [
                    entities.dipendente.DipendenteEntity.AltroRedditoFiscale,
                    entities.dipendente.DipendenteEntity.AltroRedditoPrev,
                ],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO16',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },

            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_EREDI.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_EREDI.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_EREDI.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_EREDI.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratoreEredi,
                entity: [entities.dipendente.DipendenteEntity.Erede],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO18',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_PRECEDENTI_RAPPORTI.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_PRECEDENTI_RAPPORTI.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_PRECEDENTI_RAPPORTI.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_PRECEDENTI_RAPPORTI.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratorePrecRapporto,
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO17',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_SCADENZIARIO.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_SCADENZIARIO.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_SCADENZIARIO.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_SCADENZIARIO.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratoreScadenziario,
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO19',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_DIARIO.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_DIARIO.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_DIARIO.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_DIARIO.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratoreDiario,
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO20',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_CAMPIPERSONALIZZATI.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_CAMPIPERSONALIZZATI.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_CAMPIPERSONALIZZATI.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_CAMPIPERSONALIZZATI.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratoreCampiPersonalizzati,
                entity: [entities.dipendente.DipendenteEntity.CampoPersonalizzato],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO21',
                    permissions: {
                        codPermesso: 'ANAG_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                label: ROUTES_COLLABORATORE.COLLABORATORE_ALTERAZIONI_CONTRIBUTIVE.LABEL,
                path: ROUTES_COLLABORATORE.COLLABORATORE_ALTERAZIONI_CONTRIBUTIVE.PATH,
                name: ROUTES_COLLABORATORE.COLLABORATORE_ALTERAZIONI_CONTRIBUTIVE.NAME,
                tab: ROUTES_COLLABORATORE.COLLABORATORE_ALTERAZIONI_CONTRIBUTIVE.TAB,
                orderWizard: null,
                props: true,
                component: Childs.CollaboratoreAlterazioniContributive,
                entity: [entities.contratto.ContrattoEntity.Ente],
                meta: {
                    group: 'collaboratoreDettaglio',
                    idPaginaMsg: 'CO62',
                    permissions: {
                        codPermesso: 'ALTERA_COLL',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
        ],
    },
]
