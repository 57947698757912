import anagrafiche from './anagrafiche'
import exports from './exports'
import generics from './generics'
import movimenti from './movimenti'
import note from './note'
import risultati from './risultati'
import schedulazioni from './schedulazioni'
import tabelle from './tabelle'
import view from './view'

export default {
    anagrafiche,
    exports,
    generics,
    movimenti,
    note,
    risultati,
    schedulazioni,
    tabelle,
    view,
}
