// ContrattoDivisoreDb
// ContrattoDivisoreDb
// ContrattoEnteDb
// ContrattoFeExFestRolDb
// ContrattoFestivitaDb
// ContrattoInfortunioDb
// ContrattoIstitutoContrDb
// ContrattoMalattiaDb
// ContrattoMaternitaDb
// ContrattoMensAggDb
// ContrattoProgrRetribDb
// ContrattoStraordinarioDb
//AlteraElePagaDb
//AlteraValorizzaElePagaDb

//ENTITA DA DB che hanno una validita'
const DipendenteEntity = {
    _730: 'Dipte_730',
    AlteraDitta: 'Dipte_AlteraDitta',
    AltroRedditoFiscale: 'Dipte_AltroRedditoFiscale',
    AltroRedditoPrev: 'Dipte_AltroRedditoPrev',
    Anagrafica: 'Dipte_Anagrafica',
    ANF: 'Dipte_ANF',
    ANFConiuge: 'Dipte_ANFConiuge',
    CampoPersonalizzato: 'Dipte_CampoPersonalizzato',
    CaricoFamiglia: 'Dipte_CaricoFamiglia',
    CentroCosto: 'Dipte_CentroCosto',
    Collaboratore: 'Dipte_Collaboratore',
    Collocamento: 'Dipte_Collocamento',
    Detrazione: 'Dipte_Detrazione',
    DoppioCedolino: 'Dipte_DoppioCedolino',
    Ente: 'Dipte_Ente',
    EnteAltro: 'Dipte_EnteAltro',
    EnteFisso: 'Dipte_EnteFisso',
    Erede: 'Dipte_Erede',
    NotaCedoFissa: 'Dipte_NotaCedoFissa',
    Opzione: 'Dipte_Opzione',
    PosizioneInail: 'Dipte_PosizioneInail',
    Prestito: 'Dipte_Prestito',
    Rapporto: 'Dipte_Rapporto',
    RetribElemento: 'Dipte_RetribElemento',
    Retribuzione: 'Dipte_Retribuzione',
    SindENettoC: 'Dipte_SindENettoC',
    Socio: 'Dipte_Socio',
    SommDistaccato: 'Dipte_SommDistaccato',
    Storico: 'Dipte_Storico',
    TFRFPC: 'Dipte_TFRFPC',
    UnitaProduttiva: 'Dipte_UnitaProduttiva',
    VoceFissa: 'Dipte_VoceFissa',
} as const

export type DipendenteEntityKey = keyof typeof DipendenteEntity
export type DipendenteEntityDbValue = (typeof DipendenteEntity)[DipendenteEntityKey]

export default { DipendenteEntity }
