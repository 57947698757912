import storicoDipendentiViews from '@paghe/views/anagrafiche/storico/dipendenti'

const tabPropsDefault: TabProps = {
    TITLE: 'Storico validità dipendenti',
    ICON: 'fa-regular fa-users-between-lines',
    GROUP: 'storicoValDipte',
    CODPERMESSO: 'ANAG_DIPTE',
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        id: Number(route.params?.id),
        year: Number(route.params.year),
        month: Number(route.params.month),
        //day: Number(route.params?.day ?? 1),
        dittaId: Number(route.params.dittaId),
        dittaPadreId: Number(route.params.dittaPadreId),
    }
}

export const SETTINGS = Object.freeze({
    ELENCO: {
        TITLE: 'Elenco dipendenti - Storico validità - Anagrafica - Corrente - Gestione - Paghe',
        LABEL: 'Storico dipendenti',
        PATH: 'storicoDipendente/:year/:month/:dittaId/:dittaPadreId',
        NAME: 'StoricoElencoDipendente',
        TAB: { ...tabPropsDefault },
    },
    DETTAGLIO: {
        TITLE: 'Dettaglio dipendente - Dipendenti - Storico validità - Anagrafici - Gestione - Paghe',
        LABEL: 'Dettaglio storico dipendente',
        PATH: 'storicoDipendente/:year/:month/:dittaId/:dittaPadreId/:id',
        NAME: 'StoricoDipendenteDettaglio',
        TAB: { ...tabPropsDefault, TITLE: 'Storico validità dipendente' },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.ELENCO.PATH,
            name: SETTINGS.ELENCO.NAME,
            component: storicoDipendentiViews.Elenco,
            meta: {
                title: SETTINGS.ELENCO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: propsFn,
        },
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            props: propsFn,
            component: storicoDipendentiViews.dettaglio.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
        },
    ],
}
